export enum StrategyType {
  SimpleDCAIn = 'Simple DCA In',
  DCAIn = 'DCA In',
  DCAOut = 'DCA Out',
  DCAPlusIn = 'DCA+ In',
  DCAPlusOut = 'DCA+ Out',
  WeightedScaleIn = 'Weighted Scale In',
  WeightedScaleOut = 'Weighted Scale Out',
  StreamingSwap = 'Streaming Swap',
}
